var triggerOff, triggerOn;

document.addEventListener("turbo:load", function() {
  urls.init()
});

triggerOn = function(url_id, count) {
  $('.url_box').each(function(index, domEle) {
    if ($(domEle).attr('data-id') === url_id) {
      $(domEle).addClass('trigger');
      setTimeout(triggerOff, 1000);
    }
  });
  $('.statcount').each(function(index, domEle) {
    if ($(domEle).attr('data-id') === url_id) {
      $(domEle).text(count);
    }
  });
};

triggerOff = function() {
  $('.url_box').removeClass('trigger');
};

var urls = {
  init() {
    $('.ui.dropdown.button').dropdown();
    $('.ui.accordion.search').accordion();
    $('#urls_search input').keyup(function() {
      $.get($('#urls_search').attr('action'), $('#urls_search').serialize(), null, 'script');
      return false;
    });
    
    $(".ui.button.notificationbutton").click(function() {
      $('.ui.accordion.notification').accordion("toggle", 0);
    });
  }
}

window.urls = urls;
