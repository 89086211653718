import consumer from "./consumer"

const urlChannel =consumer.subscriptions.create("UrlChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel

    let counterbox = $("#url_statistic[data-id='" + data.message + "'")
    let counter = $(".statcount[data-id='" + data.message + "'")
    let url = $(".url[data-id='" + data.message + "'")

    let count = parseInt(counter.text())
    count += 1
    counter.text(count)

    counterbox.addClass('animate__animated animate__bounceIn');

    url.addClass('active');
    let timer = setTimeout(function() {
      url.removeClass('active');
      counterbox.removeClass('animate__animated animate__bounceIn');
    }, 300)
  },

  visit(message) {
    return this.perform('visit', { message: message });
  }
});

export default urlChannel;
