// Entry point for the build script in your package.json

import "./vendor/jquery"

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"

import controllers from "./**/*_controller.js"
controllers.forEach((controller) => {
  Stimulus.register(controller.name.replace("controllers--", ""), controller.module.default)
})

import "./channels/**/*_channel.js"

Rails.start()
ActiveStorage.start()
Turbo.session.drive = false

import 'fomantic-ui/dist/semantic'

import Highcharts from 'highcharts'
import More from 'highcharts/highcharts-more'
More(Highcharts)
import SolidGauge from 'highcharts/modules/solid-gauge'
SolidGauge(Highcharts)
window.Highcharts = Highcharts

//import "./components/**/*"
import "./src/**/*"
