document.addEventListener("turbo:load", function() {
  var smokyBG;
  $('.ui.dropdown.placeholder').dropdown({
    placeholder: false
  });
  $('.ui.dropdown').not(".button").dropdown();
  $('.message .close').on('click', function() {
    $(this).closest('.message').transition('fade');
  });
  $('.field_with_errors').each(function() {
    $(this).parent().append($(this).children().detach()).addClass('error');
    return $(this).detach();
  });
  if ($("#user_session").length > 0) {
    if ($("#wavybg-wrapper").length > 0) {
      return smokyBG = $('#wavybg-wrapper').waterpipe({
        gradientStart: '#2185D0',
        gradientEnd: '#235880',
        smokeOpacity: 0.05,
        numCircles: 1,
        maxMaxRad: 'auto',
        minMaxRad: 'auto',
        minRadFactor: 0,
        iterations: 8,
        drawsPerFrame: 4,
        lineWidth: 1,
        speed: 1,
        bgColorInner: '#ffffff',
        bgColorOuter: '#ffffff'
      });
    }
  }
});
