document.addEventListener("turbo:load", function() {
  var chart3 = new Highcharts.Chart({
     chart: {
      renderTo: 'statistic_chart_share',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false
     },
     title: {
      text: 'By share'
     },
     tooltip: {
      formatter: function() {
       return '<b>'+ this.point.name +'</b>: '+ this.percentage +' %';
      }
     },
     plotOptions: {
      pie: {
       allowPointSelect: true,
       cursor: 'pointer',
       dataLabels: {
        enabled: true,
        color: '#000000',
        connectorColor:'#000000',
        formatter: function() {
           return '<b>'+ this.point.name +'</b>: '+ this.percentage +' %';
        }
       }
      }
     },
    series: [{
      type: 'pie',
      name: 'Statistic share',
      data: $("#stats").data("stats").urls
     }]
  });

  
  var chart1 = new Highcharts.Chart({
    chart: { 
      renderTo: 'statistic_chart_overview',
        zoomType: 'x',
    spacingRight: 20,
    type: "spline"
    },
    title: { text: 'All hits over the past 4 weeks' },
    xAxis: { type: 'datetime',
      maxZoom: 14 * 24 * 3600000,
      dateTimeLabelFormats: {
        month: '%e. %b',
        year: '%b'
        },
        title: {
        text: null
        } },
    yAxis: {
      title: { text: 'Hits'},
      min: 0,
      startOnTick: false,
      showFirstLabel: false
    },
    credits : {
      enabled: false
    },
    series: $("#stats").data("stats").series
  });
  
  var chart2 = new Highcharts.Chart({
    chart: {
      renderTo: 'statistic_chart_counts',
      defaultSeriesType: 'bar'
    },
    title: {
      text: 'All hits comparable'
    },
    xAxis: {
      categories: $("#stats").data("stats").categories,
      title: {
      text: null
      }
    },
    yAxis: {
      min: 0,
      title: {
      text: 'Hits',
      align: 'high'
      }
    },
    tooltip: {
      formatter: function() {
      return ''+
        this.x +' ' + this.series.name +': '+ this.y +' hits';
      }
    },
    plotOptions: {
      bar: {
      dataLabels: {
          enabled: true
      }
      }
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      x: 0,
      y: 100,
      floating: false,
      borderWidth: 1,
      backgroundColor: '#FFFFFF',
      shadow: true
    },
    credits: {
      enabled: false
    },
        series: [
    {
      name: 'in a year',
      data: $("#stats").data("stats").year
    }, {
      name: 'in a month',
      data: $("#stats").data("stats").month
    }, {
        name: 'in a week',
      data: $("#stats").data("stats").week
    }]
  });


  var chart3 = new Highcharts.Chart({
     chart: {
      renderTo: 'statistic_chart_share',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false
     },
     title: {
      text: 'Share by User Agents'
     },
     tooltip: {
      formatter: function() {
       return '<b>'+ this.point.name +'</b>: '+ this.percentage +' %';
      }
     },
     plotOptions: {
      pie: {
       allowPointSelect: true,
       cursor: 'pointer',
       dataLabels: {
        enabled: true,
        color: '#000000',
        connectorColor:'#000000',
        formatter: function() {
           return '<b>'+ this.point.name +'</b>: '+ this.percentage +' %';
        }
       }
      }
     },
    series: [{
      type: 'pie',
      name: 'Statistic share',
      data: $("#stats_table").data("user-agents")
     }]
  });

});
